@text-black-color: rgba(0, 0, 0, 0.85);
@text-white-color: #fff;

.ant-btn {
  background-color: #d3e3c7;
  border-color: #d3e3c7;
  border-radius: 24px;
  height: 36px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.3);
    border-color: #d3e3c7;
    background-color: #d3e3c7;
    color: @text-black-color;
  }

  &.ant-btn-primary {
    background-color: #6473af;
    border-color: #6473af;

    &:hover {
      color: @text-white-color;
    }
  }

  &.ant-btn-danger {
    background-color: #48287e;
    border-color: #48287e;

    &:hover {
      color: @text-white-color;
    }
  }

  &.ant-btn-link {
    color: #1890ff;
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  &:disabled {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: 20px;

    .ant-modal-header {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 15px;
        height: 28px;
        width: 6px;
        background-color: #48287e;
      }
    }
  }
}

.ant-select {
  .ant-select-selection {
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    box-shadow: none;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 4px 1px rgba(49, 17, 58, 0.2);
    }

    &.ant-select-selection--multiple {
      padding: 8px 5px;

      .ant-select-selection__choice {
        background-color: #b6d7e3;
      }
    }
  }

  &.ant-select-focused {
    .ant-select-selection {
      box-shadow: 0 0 4px 1px rgba(49, 17, 58, 0.2);
    }
  }
}

.ant-input-number {
  border-radius: 5px;
  height: 36px;
  border: 1px solid #d9d9d9;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    box-shadow: 0 0 4px 1px rgba(49, 17, 58, 0.2);
    border: 1px solid #d9d9d9;
  }

  input {
    height: 34px;
  }

  &.ant-input-number-focused {
    box-shadow: 0 0 4px 1px rgba(49, 17, 58, 0.2);
    border: 1px solid #d9d9d9;
  }
}

.ant-input-search {
  border-radius: 5px;
  height: 36px;
  border: 1px solid #d9d9d9;
  outline: none;
  box-shadow: none;

  input {
    height: 34px;
    outline: none !important;
    box-shadow: none !important;
  }

  &.ant-input-affix-wrapper {
    .ant-input:not(.ant-input-disabled) {
      border: 1px solid #d9d9d9 !important;

      &:focus,
      &:active {
        box-shadow: 0 0 4px 1px rgba(49, 17, 58, 0.2) !important;
        border: 1px solid #d9d9d9 !important;
      }
    }

    &:hover {
      .ant-input:not(.ant-input-disabled) {
        box-shadow: 0 0 4px 1px rgba(49, 17, 58, 0.2) !important;
        border: 1px solid #d9d9d9 !important;
      }
    }
  }
}

.ant-switch {
  &.ant-switch-checked {
    background-color: #6473af;
  }
}

.ant-table {
  tr {
    &:hover {
      td {
        background: transparent !important;
      }
    }
  }
}

.ant-pagination {
  i {
    color: #48287e;
  }

  .ant-pagination-item {
    a {
      color: @text-black-color;
    }

    &:hover {
      a {
        color: #48287e;
      }
    }

    &.ant-pagination-item-active {
      background: #48287e;
      border: 1px solid transparent;

      a {
        color: @text-white-color;
      }
    }
  }
}

.ant-calendar-picker {
  border-radius: 5px;
  height: 36px;
  outline: none;
  box-shadow: none;

  &:hover {
    .ant-calendar-picker-input {
      border: 1px solid #d9d9d9 !important;
      box-shadow: 0 0 4px 1px rgba(49, 17, 58, 0.2) !important;
    }
  }
}

.ant-layout-header {
  .ant-menu {
    .ant-menu-item-selected {
      background: #6473af;
    }
  }
}

.ant-menu-submenu {
  .ant-menu-item-selected {
    background-color: #6473af !important;
  }
}

.ant-table {
  .ant-table-thead {
    th {
      background: #fff;
    }
  }
}
.ant-badge-count {
  background: #48287e;
}
