@import './base.less';

body {
  #content {
    display: flex;
    position: relative;

    #app-background {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: url('./img/FondoWeb.svg');
      background-size: cover;
    }

    #content-row {
      flex: 1;
      z-index: 2;

      #content-wrapper {
        background: @text-white-color;
        padding: 30px 0;
        box-shadow: 0 0 4px 8px rgba(49, 17, 58, 0.5);

        .info-band {
          background: #b6d7e3;
          margin: 2vh 0;
          padding: 2px 8px;
          font-weight: bold;
          font-size: 0.9rem;
        }

        .player-card-wrapper {
          min-height: 280px;

          .player-card {
            border-radius: 15px;
            box-shadow: 0 0 4px 2px rgba(49, 17, 58, 0.15);

            .ant-card-actions {
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;

              li {
                margin: 6px 0;

                i {
                  &:hover {
                    color: rgba(0, 0, 0, 0.45);
                  }
                }
              }
            }

            &:hover {
              cursor: pointer;
              box-shadow: 0 0 4px 4px rgba(49, 17, 58, 0.4);
            }
          }
        }

        .deal-card-wrapper {
          min-height: 165px;

          .deal-card {
            border-radius: 15px;
            box-shadow: 0 0 4px 2px rgba(49, 17, 58, 0.15);

            .ant-card-actions {
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;

              li {
                margin: 6px 0;

                i {
                  &:hover {
                    color: rgba(0, 0, 0, 0.45);
                  }
                }
              }
            }

            &:hover {
              cursor: pointer;
              box-shadow: 0 0 4px 4px rgba(49, 17, 58, 0.4);
            }

            &.past-deal {
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  #header {
    padding: 0 25px;
  }

  #desktop-menu {
    display: none;
  }

  #mobile-menu {
    display: block !important;

    .ant-menu-submenu-title {
      padding-right: 0;
      text-align: right;
    }
  }

  .admin-menu-label {
    display: none;
  }

  #teams-page,
  #users-list {
    max-width: 100vw;
    overflow-y: scroll;
  }

  .ant-tabs {
    max-width: 100vw;
  }

  .ant-tabs-tab {
    margin-right: 0 !important;
    text-align: center !important;

    i {
      margin-right: 0 !important;
    }
  }
}
